@import '../variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.decompte-container {
  position: relative;
  margin: auto;

  h3 {
    letter-spacing: 7px;
    font-size: 1.25rem;
  }
}