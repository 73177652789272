$white: #ffffff;
$black: #000000;
$grey-text: #525252;

$palaBlue: #005378;

$fireBlue: #062C3F;
$fireRed: #BF1515;

$sand: #BFADA3;

$palaPink: #DB7093;
