@import '../variables.scss';

footer {
    background-color: $palaBlue;

    .footer-body  {
        img {
            width: auto;
            max-height: 150px;
        }

        ul {
            list-style: none;
            padding: 0;
            margin: 0;
            
            li {
                padding: 5px 0;
                font-size: 0.825rem;
                color: $white;
                font-weight: bold;

                a {
                    text-decoration: none;
                    color: $white;
                }
            }
        }
    }
}

.footer-copy {
    color: $palaBlue;
}

div.legal-mentions {
    p, h2, h3{
        text-align: justify;
    }
}