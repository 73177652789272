@import 'variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.billet-card {
  box-shadow: 3px 3px 25px 0 rgba(0, 0, 0, 0.8);

  .card-title {
    line-height: 24px;
  }
  .card-subtitle {
    color: $grey-text;
  }

  .strong {
    font-size: 0.875rem;
    width: 100%;
  }

  .card-price {
    text-align: center;
    font-size: 60px;
    font-weight: 200;
  }  
}

@include media-breakpoint-down(xl) {
  .billet-card {
    .card-title {
      height: 34px;
      line-height: 17px;

    }
  }
}

@include media-breakpoint-down(lg) {
  .billet-card {
    .card-subtitle {
      color: #848484;
    }
  
    .card-price {
      font-size: 50px;
    }
  }
}