#affiche-2024 {
    margin: 20px 0;
    .affiche-container {
        position: relative;

        img {
            max-height: 90vh;
            max-width: 100%;
        }
        
        .overlay {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba($color: #000000, $alpha: 0.6);
            width: 100%;
            height: 100%;
            transition: all 0.3s ease-in-out;;
            -webkit-transition: all 0.3s ease-in-out;
            opacity: 0;

            .affiche-title {
                position: absolute;
                top: 40%;
                font-size: 2em;
            }
        }

        &:hover {
            -webkit-transform: scale(1.05);
            transform: scale(1.05);
            -webkit-transition: all 0.7s;
            transition: all 0.7s;

            cursor: zoom-in;

            .overlay {
                opacity: 1;
            }
        }
    }
    
}