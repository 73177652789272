@import 'variables.scss';

.slick-track{
	display: flex;
	
	.slick-slide{
		display: flex;
		height: auto;
		align-items: center; //optional
		justify-content: center; //optional
	}
}