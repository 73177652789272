@import '../variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.aspp-header {
  width: 100%;
  background-color: $white;
  border-bottom: 4px solid $palaBlue;

  a {
    text-decoration: none;
  }
  
  .dropdown {
    position: relative;
    display: inline-block;
  }
  
  .dropdown-content {
    display: none;
    position: absolute;
    background-color: #f1f1f1;
    min-width: 160px;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    z-index: 1;
  }
  
  .dropdown-content a {
    color: black;
    padding: 12px 16px;
    text-decoration: none;
    display: block;
  }
  
  .dropdown-content a:hover {background-color: #ddd;}
  
  .dropdown:hover .dropdown-content {display: block;}
  
  .dropdown:hover .dropbtn {background-color: #3e8e41;}

  .aspp-logo {
      width: auto;
      height: 90px;
  }

  h1 {
      font-size: 1.5rem;
      font-weight: 700;
      margin-bottom: 0;
  }

  h2 {
      font-size: 1.2rem;
      font-weight: 600;
  }

  h1, h2 {
      color: $palaBlue;
  }

  .volume-box {
    button {
      min-width: 60px;
    }
  }

  .date {
    color: $white;
    font-weight: bold;

    .day {
      line-height: 34px;
      
      >:nth-child(1) {
        font-size: 32px;
      }

      >:nth-child(2) {
        font-size: 30px;
        font-weight: 400;
      }
    
    }
  }
}

@include media-breakpoint-down(lg) {
  .aspp-header {
    .logo-name {
      h1 {
          font-size: 0.9rem;
          font-weight: 700;
          margin-bottom: 0;
      }
    
      h2 {
          font-size: 0.75rem;
          font-weight: 600;
      }
    }
  }
}

@include media-breakpoint-down(md) {
  .aspp-header {
    .logo-name {
      h1 {
          font-size: 1rem;
          font-weight: 700;
          margin-bottom: 0;
      }
    
      h2 {
          font-size: 0.75rem;
          font-weight: 600;
      }
    }
    .date {
      .day {
        line-height: 28px;
        
        >:nth-child(1) {
          font-size: 26px;
        }
  
        >:nth-child(2) {
          font-size: 24px;
          font-weight: 400;
        }
      
      }
    }
  }
}