@import '../variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.decompte-container {
  .time-square {
    position: relative;
    position: relative;
    min-width: 100px;
    
    &:not(:last-child) {
      margin-right: 20px;
    }

    .time {
      align-items: center;
      line-height: 90px;
      background-color: rgba($color: $palaBlue, $alpha: 0.6);
      width: 90px;
      height: 90px;
      border-radius: 5px;
      font-size: 35px;
      font-weight: 600;
      color: $white;
    }

    .label {
      font-size: 20px;
      font-weight: 500;
      color: $white;
    }
  }
}

@include media-breakpoint-down(md) {
  .decompte-container {
    .time-square {
      position: relative;
      position: relative;
      min-width: 50px;
      
      &:not(:last-child) {
        margin-right: 10px;
      }
  
      .time {
        align-items: center;
        line-height: 60px;
        background-color: rgba($color: $palaBlue, $alpha: 0.6);
        width: 60px;
        height: 60px;
        border-radius: 5px;
        font-size: 25px;
        font-weight: 600;
        color: $white;
      }
  
      .label {
        font-size: 12px;
        font-weight: 500;
        color: $white;
      }
    }
  }
}
