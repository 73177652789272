@import 'variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@mixin font-face($name, $path, $weight: null, $style: null, $exts: eot woff2 woff ttf svg) {
  $src: null;

  $extmods: (
      eot: "?",
      svg: "#" + str-replace($name, " ", "_"),
  );

  $formats: (
      otf: "opentype",
      ttf: "truetype",
  );

  @each $ext in $exts {
      $extmod: if(map-has-key($extmods, $ext), $ext + map-get($extmods, $ext), $ext);
      $format: if(map-has-key($formats, $ext), map-get($formats, $ext), $ext);
      $src: append($src, url(quote($path + "." + $extmod)) format(quote($format)), comma);
  }

  @font-face {
      font-family: quote($name);
      font-style: $style;
      font-weight: $weight;
      src: $src;
      font-display: swap;
  }
}

@include font-face(Montserrat, './fonts/Montserrat/Montserrat-Light', 300, normal, ttf);
@include font-face(Montserrat, './fonts/Montserrat/Montserrat-Regular', 400, normal, ttf);
@include font-face(Montserrat, './fonts/Montserrat/Montserrat-Medium', 500, italic, ttf);
@include font-face(Montserrat, './fonts/Montserrat/Montserrat-SemiBold', 600, normal, ttf);
@include font-face(Montserrat, './fonts/Montserrat/Montserrat-Bold', 700, normal, ttf);
@include font-face(Montserrat, './fonts/Montserrat/Montserrat-ExtraBold', 800, normal, ttf);
@include font-face(Montserrat, './fonts/Montserrat/Montserrat-Black', 900, normal, ttf);

body {
  font-family: 'Montserrat', 'OpenSans';
}

.w {
  &-30 { width: 30%; }
  &-35 { width: 35%; }
  &-40 { width: 40%; }
  &-50 { width: 50%; }
  &-60 { width: 60%; }
  &-65 { width: 65%; }
  &-70 { width: 70%; }
  &-75 { width: 75%; }
  &-80 { width: 80%; }
  &-85 { width: 85%; }
  &-90 { width: 90%; }
}

.h {
  &-20p { height: 20px }
  &-30p { height: 30px }
  &-40p { height: 40px }
  &-60p { height: 60px; }
}

.btn {
  &-mw-20 { min-width: 20px }
  &-mw-30 { min-width: 30px; }
  &-mw-40 { min-width: 40px; }
  &-mw-50 { min-width: 50px; }
  &-mw-60 { min-width: 60px; }
  &-mw-80 { min-width: 80px; }
}

.font {
  &-10{ font-size: 10px; }
  &-12{ font-size: 12px; }
  &-14{ font-size: 14px; }
  &-16{ font-size: 16px; }
  &-18{ font-size: 18px; }
  &-20{ font-size: 20px; }
  &-22{ font-size: 22px; }
  &-24{ font-size: 24px; }
  &-26{ font-size: 26px; }
  &-28{ font-size: 28px; }
  &-30{ font-size: 30px; }
  &-32{ font-size: 32px; }
  &-34{ font-size: 34px; }
}

.fw {
  &-100{ font-weight: 100; }
  &-200{ font-weight: 200; }
  &-300{ font-weight: 300; }
  &-400{ font-weight: 400; }
  &-500{ font-weight: 500; }
  &-600{ font-weight: 600; }
  &-700{ font-weight: 700; }
  &-800{ font-weight: 800; }
}

.font-bold {
  font-family: 'MontserratBold';
}

.background-white {
  background: $white;
}

.bg-blue-pala {
  background-color: $palaBlue;
}

.bg-pink {
  background-color: $palaPink;
}

.btn {
  &.bg-blue-pala {
    color: $white;

    &:hover {
      color: $palaBlue;
      background-color: $white;
    }
  }

  &.bg-pink {
    color: $white;

    &:hover {
      color: $palaPink!important;
      background-color: $white;
    }
  }
}

.text-blue-pala {
  color: $palaBlue;
}

.text-light-pink {
  color: #FFB6C1;
}

.text-hot-pink {
  color: #FF69B4;
}


.background-fire-red{
  background: $fireRed;
}

.text-grey {
  color: $grey-text;
}

.text-gold {
  color: $sand;
}

.btn-gold {
  background-color: $sand;
  border-color: $sand;
  text-decoration: none;
  color: $white;

  &:hover {
    background-color: $white;
    border-color: $white;
    color: $sand;
  }

  &:active {
    background-color: $sand!important;
    border-color: $sand!important;
    color: $white!important;
  }
}

.btn-outline-gold {
  background-color: $white;
  border-color: $sand;
  text-decoration: none;
  color: $sand;

  &:active {
    background-color: $sand;
    border-color: $sand;
    color: $white
  }
}

.btn-circle {
    padding: 6px 0px;
    border-radius: 15px;
    text-align: center;
    font-size: 12px;
    line-height: 1.42857;

}

.vertical-text {
  width:1px;
  word-wrap: break-word;
  white-space:pre-wrap; 
}

.list-none {
  list-style: none;
}

@include media-breakpoint-up(xs) {
  .w-xs {
    &-25 { width: 25%!important; }
    &-50 { width: 50%!important; }
    &-70 { width: 70%!important; }
    &-75 { width: 75%!important; }
    &-80 { width: 80%!important; }
    &-85 { width: 85%!important; }
    &-100 { width: 100%!important; }
  }
}

@include media-breakpoint-up(sm) {
  .w-sm {
    &-25 { width: 25%!important; }
    &-50 { width: 50%!important; }
    &-70 { width: 70%!important; }
    &-75 { width: 75%!important; }
    &-80 { width: 80%!important; }
    &-85 { width: 85%!important; }
    &-100 { width: 100%!important; }
  }
}

@include media-breakpoint-up(md) {
  .w-md {
    &-25 { width: 25%!important; }
    &-50 { width: 50%!important; }
    &-70 { width: 70%!important; }
    &-75 { width: 75%!important; }
    &-80 { width: 80%!important; }
    &-85 { width: 85%!important; }
    &-100 { width: 100%!important; }
  }
}

@include media-breakpoint-up(lg) {
  .w-lg {
    &-25 { width: 25%!important; }
    &-50 { width: 50%!important; }
    &-70 { width: 70%!important; }
    &-75 { width: 75%!important; }
    &-80 { width: 80%!important; }
    &-85 { width: 85%!important; }
    &-100 { width: 100%!important; }
  }
}

.warning {
  color: #D3675C;
}

.strong {
  font-weight: bold;
  text-transform: uppercase;
  font-size: 1.0rem;
}

.text-center {
  text-align: center;
}

#services {
  .card-img-top {
    max-height: 160px;
    object-fit: cover;
    height: 160px;
    width: auto;
  }
}

#tarifs {
  &.parallax {
    background-image: url("./medias/parallax_background4.jpg");
    /* Une hauteur variable */
    min-height: 100vh;

    /* L'effet de parallax */
    background-attachment: fixed;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;

    .arena-image {
      box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.8);
    }

    .arena-areas-mention {
      padding: 20px 20px;
      background-color: rgba(0, 0, 0, 0.3);
      width: 100%;
    }
    .mention {
      padding: 2px 10px;
      margin-top: 20px;
      color: $white;
      font-weight: 600;
      //background-color: rgba(255, 255, 255, 0.8);
    }
  }

  .section-title {
    font-weight: 600;
    color: $white;
    text-transform: uppercase;
  }

  .section-subtitle {
    color: $white;
  }
}
