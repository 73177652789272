@import 'variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.services-item {

    &:first-child {
        .services-item-cover {
            background-image: url('./medias/bal2023/PIX_8256.jpg');
        }
    }
    
    &:nth-child(2) {
        .services-item-cover {
            background-image: url('./medias/services/the_sonotown.jpg');
        }
    }

    &:last-child {
        .services-item-cover {
            background-image: url('./medias/bal2023/PIX_8341.jpg');
        }
    }

    .services-item-cover {
        background-size: cover;
        background-position-x: center;
        padding-bottom: 10rem;
        padding-top: 10rem;

        .services-item-title  {
            h3, h4 {
                color: $white;
                font-size: 36px;
            }

            h3 {
                font-weight: 400;
            }

            h4 {
                font-weight: 800;
            }
        }

        .services-cta {
            button {
                font-weight: 400;
                text-transform: uppercase;
                font-size: 12px;
            }
        }
    }
}