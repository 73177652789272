@import '../variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.video-container {
  width: 100%;
  //max-height: 90vh;
  position: relative;

  .video-player {
    &.fullscreen {
      overflow: hidden;
      video {
        object-fit:cover;
      }
    }
  }

  .video-jumbo {
    position: absolute;
    top: 30%;
    padding: 2em;
    //background-color: rgba($color: #FFFFFF, $alpha: 0.2);

    .video-title {
      text-transform: uppercase;
      font-weight: 700;
      color: $white;
  
      h1 {
        font-size: 2.5rem;
        font-weight: 800;
      }
  
      h2 {
        font-size: 1.5rem;
        font-weight: 600;
      }
    }
  
    .video-cta {
      .btn {
        padding: 10px;
        font-weight: 600;
        background-color: rgba($color: $white, $alpha: 0.6);
        color: $palaBlue;
        height: 90px;
        line-height: 70px;
      }
    }
  }
}

@include media-breakpoint-down(lg) {
  .video-container {
    .video-jumbo {
      top: 20%;

      .video-title {
        h1 {
          font-size: 2rem;
        }
    
        h2 {
          font-size: 1rem;
        }
      }
    }

    .video-logo {
      bottom: 250px;
      //display: none;
    }
  }
}

@include media-breakpoint-down(md) {
  .video-container {
    width: 100%;
    max-height: 85vh;
    
    .video-jumbo {
      top: 20%;

      .video-title {
        h1 {
          font-size: 1.5rem;
        }
    
        h2 {
          font-size: 0.75rem;
        }
      }

      .video-cta {
        .btn {
          padding: 10px;
          font-weight: 600;
          background-color: rgba($color: $white, $alpha: 0.6);
          color: $palaBlue;
          height: 60px;
          line-height: 40px;
          font-size: 12px;
        }
      }
    }

    .video-logo {
      img {
        width: 125px;
        margin-right: 10px;
      }
    }


  }
}

@include media-breakpoint-down(sm) {
  .video-container {
    .video-jumbo {
      top: 10%;

      .video-title {
        h1 {
          font-size: 1.25rem;
        }
    
        h2 {
          font-size: 0.75rem;
        }
      }
    }

    .video-logo {
      position: relative;
      text-align: right;
      width: 100%;
      right: 0;
      bottom: 250px;
      //display: none;

      img {
        width: 125px;
        margin-right: 10px;
      }
    }
  }
  
}
