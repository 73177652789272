@import '../variables.scss';

@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

.video-section {
  margin: 0 0 20px 0;
  .video-section-container {
    position: relative;

    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba($color: #000000, $alpha: 0.6);

      .video-section-play {
        font-size: 68px;
      }
    }
  }
}
